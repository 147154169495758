import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../atoms/Button';

const challenges = [
	{ id: 'boats', name: 'Boat challenge', description: 'List of boats, need to be login' },
	// { id: 'todo', name: 'Todo challenge', description: '' },
];

const ListChallenges = () => {
	return (
		<div>
			<h2>Challenge List</h2>
			<ul>
				{challenges.map((challenge) => (
					<li key={challenge.id}>
						{challenge.name}
						{challenge.description}
						<Link to={`/challenge/${challenge.id}`}>
							<Button text="View button" onClick={() => {}} />
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default ListChallenges;
