import { CASE_TYPE } from '../../../../components/pages/PathfinderVisualizer/ArrayContext';

export function* simpleStairs(array: number[][]): IterableIterator<{ [s: number]: number[] }> {
	let x = 0;
	let y = 0;
	for (let i = 0; i < array.length; i++) {
		for (let j = 0; j < array[i].length; j++) {
			if (x === i && y === j) {
				if (array[i][j] === CASE_TYPE.EMPTY) array[i][j] = CASE_TYPE.WALL;
				x++;
				y++;
				yield {
					1: [i, j],
				};
			}
		}
	}
}
