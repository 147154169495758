export function getRandomArbitrary(min: number, max: number) {
	return Math.ceil(Math.random() * (max - min) + min);
}

export function getRandomOddNumber(min: number, max: number) {
	let randNum = getRandomArbitrary(min, max);
	if (randNum % 2 === 0) {
		if (randNum === max) {
			randNum = randNum - 1;
		} else {
			randNum = randNum + 1;
		}
	}
	return randNum;
}
export function getRandomEvenNumber(min: number, max: number) {
	let randNum = getRandomArbitrary(min, max);
	if (randNum % 2 === 1) {
		if (randNum === max) {
			randNum = randNum - 1;
		} else {
			randNum = randNum + 1;
		}
	}
	return randNum;
}

export const generateArrayRandomNumber = (numberOfElement: number, maxValue: number) => {
	const newArray = [];
	for (let i = 0; i < numberOfElement; i++) {
		const randomNumber = getRandomArbitrary(1, maxValue);
		newArray.push(randomNumber);
	}
	return newArray;
};
