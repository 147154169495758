import React, { createContext, useMemo, useState } from 'react';

const NUMBER_OF_COLUMN = 55;
const NUMBER_OF_ROWS = 35;

export const enum CASE_TYPE {
	EMPTY = 0,
	WALL = 1,
	START = 2,
	FINISH = 3,
	STEP = 4,
}

const INIT_ARRAY = () => {
	const array = new Array(NUMBER_OF_ROWS).fill(null).map(() => new Array(NUMBER_OF_COLUMN).fill(0));
	array[10][10] = CASE_TYPE.START;
	array[9][9] = CASE_TYPE.STEP;
	array[11][40] = CASE_TYPE.FINISH;
	return array;
};

export const ArrayDataContext = createContext({
	array: INIT_ARRAY(),
	rerender: 0,
});

export const ArrayApiContext = createContext({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setArray: (s: number[][]) => {},
	fnInitArray: INIT_ARRAY,
	setRender: () => {},
	nbOfColumn: NUMBER_OF_COLUMN,
	nbOfRow: NUMBER_OF_ROWS,
});

export const ArrayContextProvider = (props: any) => {
	const setArray = (array: number[][]) => {
		state.array = array;
		setState({ ...state, array: array });
	};

	const setRender = () => {
		state.rerender = state.rerender + 1;
		setState({ ...state, rerender: state.rerender });
	};

	const initState = {
		array: INIT_ARRAY(),
		rerender: 0,
	};

	const apiInitState = {
		setArray: setArray,
		fnInitArray: INIT_ARRAY,
		nbOfColumn: NUMBER_OF_COLUMN,
		nbOfRow: NUMBER_OF_ROWS,
		setRender: setRender,
	};

	const [state, setState] = useState(initState);

	const stateMemo = useMemo(() => {
		return state;
	}, [state.rerender]);

	const apiStateMemo = useMemo(() => {
		return apiInitState;
	}, []);

	return (
		<ArrayDataContext.Provider value={stateMemo}>
			<ArrayApiContext.Provider value={apiStateMemo}>{props.children}</ArrayApiContext.Provider>
		</ArrayDataContext.Provider>
	);
};
