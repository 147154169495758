import * as React from 'react';
import { useContext, useState } from 'react';
import { Vector } from '../../../type/math';
import { ArrayDataContext, CASE_TYPE } from '../../pages/PathfinderVisualizer/ArrayContext';
import Row from './Row';

export type DRAGGING_ELEMENT = { caseType: CASE_TYPE } & Vector;
const VisualizeArray: React.FC<VisualizeArrayProps> = ({ forceRender }) => {
	const [dragging, setDragging] = useState<DRAGGING_ELEMENT | undefined>(undefined);
	const { array } = useContext(ArrayDataContext);

	const tds = array.map((row, index) => {
		return (
			<tr key={index} id={`PathfinderVisualizer-column-${index}`}>
				<Row
					row={row}
					y={index}
					dragging={dragging}
					setDragging={setDragging}
					array={array}
					rowHash={row.join('-')}
					forceRender={forceRender}
				/>
			</tr>
		);
	});
	return <>{tds}</>;
};

type VisualizeArrayProps = {
	forceRender: number;
};

export default VisualizeArray;
