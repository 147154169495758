export function* mergeSort(array: number[]): IterableIterator<{ [s: number]: number[] }> {
	function* merge(
		array: number[],
		left: number,
		mid: number,
		right: number,
	): IterableIterator<{ [s: number]: number[] }> {
		const leftArr = [];
		for (let index = left; index <= mid; index++) {
			leftArr.push(array[index]);
		}
		const rightArr = [];
		for (let index = mid + 1; index <= right; index++) {
			rightArr.push(array[index]);
		}
		let leftIndex = 0;
		let rightIndex = 0;
		let index = left;

		while (leftIndex < leftArr.length && rightIndex < rightArr.length) {
			if (leftArr[leftIndex] < rightArr[rightIndex]) {
				array[index] = leftArr[leftIndex];
				leftIndex++;
			} else {
				array[index] = rightArr[rightIndex];
				rightIndex++;
			}
			yield {
				1: [index],
				2: [leftIndex + left, rightIndex + mid + 1],
			};
			index++;
		}
		while (leftIndex < leftArr.length) {
			array[index] = leftArr[leftIndex];
			yield {
				1: [index],
				2: [leftIndex + left, rightIndex + mid + 1],
			};
			leftIndex++;
			index++;
		}
		while (rightIndex < rightArr.length) {
			array[index] = rightArr[rightIndex];
			yield {
				1: [index],
				2: [leftIndex + left, rightIndex + mid + 1],
			};
			rightIndex++;
			index++;
		}
	}

	function* mergeSort_(array: number[], left: number, right: number): IterableIterator<{ [s: number]: number[] }> {
		if (right <= left) return;
		const mid = Math.floor(left + (right - left) / 2);

		yield* mergeSort_(array, left, mid);
		yield* mergeSort_(array, mid + 1, right);
		yield* merge(array, left, mid, right);
	}

	yield* mergeSort_(array, 0, array.length - 1);
}

export default mergeSort;
