import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';

const kakashielAxiosInstance: AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
	},
});

kakashielAxiosInstance.interceptors.request.use(
	(config) => {
		const csrfToken = Cookies.get('XSRF-TOKEN');
		if (csrfToken) {
			config.headers['X-XSRF-TOKEN'] = csrfToken;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export function setupInterceptors(setIsLogin: (arg0: boolean) => void) {
	kakashielAxiosInstance.interceptors.response.use(
		(response) => {
			console.log('response', response);
			if (response.data.includes('Login with OAuth 2.0')) {
				setIsLogin(false);
				window.location.href = '/login';
				throw new Error('Need to login');
			}

			return response;
		},
		async (error) => {
			return Promise.reject(error);
		},
	);
}

export default kakashielAxiosInstance;
