import React from 'react';
import './styles.css';
import SeparatorVertical from '../../atoms/SeparatorVertical';
import Dropdown from '../../atoms/Dropdown';
import Separator from '../../atoms/Separator';
import Link from '../../atoms/Link';

const Navbar: React.FC = () => {
	return (
		<div id={'navbar'}>
			<nav className={'is-size-7 has-text-weight-bold'}>
				<Link to="/">Home</Link>
				<SeparatorVertical />
				<Dropdown text={'Algorithms'} side={'left'}>
					<Link to="/array-sorting">Array Sorting</Link>
					<Separator />
					<Link to="/tree-visualizer">Tree Visualizer</Link>
					<Separator />
					<Link to="/pathfinder-visualizer">Pathfinder Visualizer</Link>
				</Dropdown>
				<SeparatorVertical />
				<Dropdown text={'Articles'} side={'left'}>
					<Link to="/article/1">Cheatsheet</Link>
					<Separator />
					<Link to="/article/2">Kubernetes cheat sheet</Link>
					<Separator />
					<Link to="/legal/privacy-policy">Privacy Policy</Link>
				</Dropdown>
				<SeparatorVertical />
				<Link to="/login">Login</Link>
			</nav>
		</div>
	);
};

export default Navbar;
