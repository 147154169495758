import * as React from 'react';
import './styles.css';

const InputRange: React.FC<Props> = ({ id, text = 'text', onChange, min, max, value }) => {
	return (
		<div className={'InputRange-container'}>
			<label htmlFor={id}>{text}</label>
			<input type="range" id={id} onChange={onChange} min={min} max={max} value={value} />
		</div>
	);
};

type Props = {
	text: string;
	min: number;
	max: number;
	value: number;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	id: string;
};

export default InputRange;
