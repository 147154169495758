import * as React from 'react';
import { useRef, useState } from 'react';
import './styles.css';
import Triangle from '../Icons/Triangle';
import useOnClickOutside from '../../../utils/hooks/useClickOutside';

const Dropdown: React.FC<Props> = ({ text, side = 'right', children, id }) => {
	const [display, setDisplay] = useState<boolean>();
	const toggle = () => setDisplay((e) => !e);
	const squareBoxRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(squareBoxRef, () => !display || toggle());
	return (
		<div id={id} className={`dropdown`} ref={squareBoxRef} style={{ float: `${side}` }}>
			<button className={'dropdown-title'} onClick={() => toggle()}>
				{text}
				<Triangle />
			</button>
			<div className="dropdown-content" style={{ [`${side}`]: 0, display: display ? 'block' : 'none' }}>
				{children}
			</div>
		</div>
	);
};

type Props = {
	text: string;
	side?: 'left' | 'right';
	children: React.ReactNode;
	id?: string;
};

export default Dropdown;
