import React, { FunctionComponent } from 'react';
import './styles.css';
import Profile from '../../atoms/Icons/Profile';
import Navbar from '../Navbar';

const Header: FunctionComponent<Props> = () => {
	return (
		<div id={'header'} className={'is-size-6'} test-id={'header'}>
			<div className={'has-text-weight-bold'}>Damn kakashiel</div>
			<div className={'image'}>
				<Profile />
			</div>
			<Navbar />
		</div>
	);
};

type Props = Record<string, never>;

export default Header;
