import React, { FunctionComponent, useEffect } from 'react';
import Header from '../index';

const HeaderContainer: FunctionComponent<Props> = () => {
	const resizeHeaderOnScroll = (): void => {
		const distanceY = window.pageYOffset || document.documentElement.scrollTop,
			headerEl = document.getElementById('header');
		if (distanceY > 150) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			headerEl!.classList.add('header-smaller');
		} else {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			headerEl!.classList.remove('header-smaller');
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', resizeHeaderOnScroll);
	}, []);

	return <Header />;
};
type Props = Record<string, never>;

export default HeaderContainer;
