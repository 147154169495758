import React, { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Article from '../../organismes/Articles';
import ArraySorting from '../../pages/ArraySorting';
import Home from '../../pages/Home';
import Login from '../../pages/Login';
import PagesWrapper from '../../pages/PagesWrapper';
import PathfinderVisualizer from '../../pages/PathfinderVisualizer';
import { ArrayContextProvider } from '../../pages/PathfinderVisualizer/ArrayContext';
import TreeVisualizer from '../../pages/TreeVisualizer';
import OWSBoatsChallenge from '../../pagesForChallenges/OWSBoatsChallenges';
import ckadFile from './../../../Articles/2019-03-28-k8s-ckad.md';
import privacyPolicy from './../../../Articles/privacy-policy.md';
import testFile from './../../../Articles/test.md';

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<PagesWrapper>
				<Home />
			</PagesWrapper>
		),
	},
	{
		path: '/login',
		element: (
			<PagesWrapper>
				<Login />
			</PagesWrapper>
		),
	},
	{
		path: '/array-sorting',
		element: (
			<PagesWrapper>
				<ArraySorting />,
			</PagesWrapper>
		),
	},
	{
		path: '/pathfinder-visualizer',
		element: (
			<PagesWrapper>
				<ArrayContextProvider>
					<PathfinderVisualizer />
				</ArrayContextProvider>
			</PagesWrapper>
		),
	},
	{
		path: '/tree-visualizer',
		element: (
			<PagesWrapper>
				<TreeVisualizer />,
			</PagesWrapper>
		),
	},
	{
		path: '/article/1',
		element: (
			<PagesWrapper>
				<Article file={testFile} />,
			</PagesWrapper>
		),
	},
	{
		path: '/article/2',
		element: (
			<PagesWrapper>
				<Article file={ckadFile} />,
			</PagesWrapper>
		),
	},
	{
		path: '/challenge/boats',
		element: (
			<PagesWrapper>
				<OWSBoatsChallenge />
			</PagesWrapper>
		),
	},
	{
		path: '/challenge/todo',
		element: (
			<PagesWrapper>
				<OWSBoatsChallenge />,
			</PagesWrapper>
		),
	},
	{
		path: '/legal/privacy-policy',
		element: (
			<PagesWrapper>
				<Article file={privacyPolicy} />,
			</PagesWrapper>
		),
	},
]);

const AppRoutes: FC = () => {
	return (
		<>
			<RouterProvider router={router} />
		</>
	);
};

export default AppRoutes;
