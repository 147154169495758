import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './styles.css';

const Link: React.FC<Props> = ({ to, children, component, state }: Props) => {
	const child = component ? component : children;
	return (
		<RouterLink to={to} state={state} className="link remove-all-styles">
			{child}
		</RouterLink>
	);
};

type Props = {
	to: string;
	children?: React.ReactNode;
	component?: React.ReactNode;
	state?: any;
};

export default Link;
