import { textToTranslate } from '../../hooks/useTranslation';

export const englishWebsiteText: textToTranslate = {
	header: {
		title: 'Damn kakashiel',
	},
	footer: {
		circleMail: 'adrien.loustaunau@gmail.com',
		circleLinkedin: 'https://www.linkedin.com/in/adrien-l3000',
		circleGitlab: 'https://gitlab.com/kakashiel',
		circleGithub: 'https://github.com/kakashiel',
		footerText: 'Adrien Loustaunau • 2019 • Damn kakashiel',
	},
};
