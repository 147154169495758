import * as React from 'react';
import './styles.css';

const InputRadio: React.FC<Props> = ({ id, text = 'text', onChange, value, checked = false }) => {
	return (
		<div className={'InputRadio-container'} data-testid={'InputRadio-container'}>
			<input type="checkbox" id={id} onChange={onChange} value={value} checked={checked} />
			<label htmlFor={id}>{text}</label>
		</div>
	);
};

type Props = {
	text: string;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	id: string;
	checked?: boolean;
};

export default InputRadio;
