import * as React from 'react';
import { useEffect, useState } from 'react';
import Sidebar from '../../molecule/Sidebar';
import Button from '../../atoms/Button';
import InputRange from '../../atoms/InputRange';
import './styles.css';
import { generateArrayRandomNumber } from '../../../utils/number';
import Separator from '../../atoms/Separator';
import InputRadio from '../../atoms/InputRadio';
import mergeSort from '../../../utils/algorithms/mergeSort';
import { bubbleSort } from '../../../utils/algorithms/bubbleSort';
import { quickSort } from '../../../utils/algorithms/quickSort';

enum SortAlgo {
	Merge = 'merge',
	Quick = 'quick',
	Bubble = 'bubble',
	Heap = 'heap',
}

enum color {
	primary = '#e1dd72',
	secondary = '#a8c66c',
	tertiary = '#1b6535',
}

const ArraySorting: React.FC<Props> = () => {
	const [speed, setSpeed] = useState<number>(5);
	const [numberOfElement, setNumberOfElement] = useState<number>(50);
	const [sortAlgo, setSortAlgo] = useState<SortAlgo>(SortAlgo.Merge);
	const [array, setArray] = useState<number[]>([]);
	const [generateNewVisualizer, setGenerateNewVisualizer] = useState<number>(0);
	const [clickOnSort, setClickOnSort] = useState<number>(0);
	const [sortIterator, setSortIterator] = useState<IterableIterator<{ [s: number]: number[] }> | undefined>(
		undefined,
	);
	const arrayContainer = document.getElementById('ArraySorting-container-element');
	const heightMax = arrayContainer ? arrayContainer.offsetHeight : 50;

	const visualizeArray = (array: number[], levelTargets: { [s: number]: number[] }) => {
		if (!arrayContainer) return;
		arrayContainer.innerHTML = '';
		for (let i = 0; i < array.length - 1; i++) {
			const element = document.createElement('div');
			element.setAttribute('id', `ArraySorting-element-${i}`);
			element.style.backgroundColor = color.tertiary;
			for (const [key, values] of Object.entries(levelTargets)) {
				if (values.some((v) => v === i)) {
					if (key === '1') element.style.backgroundColor = color.primary;
					if (key === '2') element.style.backgroundColor = color.secondary;
				}
			}
			element.style.height = `${array[i]}px`;
			arrayContainer.appendChild(element);
			element.classList.add('ArraySorting-element');
		}
	};

	useEffect(() => {
		const newArray = generateArrayRandomNumber(numberOfElement, heightMax - 30);
		setArray(newArray);
		visualizeArray(newArray, []);
	}, [numberOfElement, generateNewVisualizer, heightMax]);

	useEffect(() => {
		const timer = setTimeout(function () {
			if (sortIterator === undefined) return;
			const it = sortIterator.next();
			if (!it.done) {
				visualizeArray(array, it.value);
				setClickOnSort((prev) => ++prev);
			}
		}, 500 - speed * 50);
		return () => clearTimeout(timer);
	}, [clickOnSort]);

	return (
		<div className={'ArraySorting-container'}>
			<Sidebar>
				<Button
					text={'Sort!'}
					onClick={() => {
						if (sortAlgo === SortAlgo.Merge) setSortIterator(mergeSort(array));
						else if (sortAlgo === SortAlgo.Bubble) setSortIterator(bubbleSort(array));
						else if (sortAlgo === SortAlgo.Quick) setSortIterator(quickSort(array));
						setClickOnSort((prev) => ++prev);
					}}
				/>
				<Separator />
				<Button text={'Generate Array'} onClick={() => setGenerateNewVisualizer((prev) => ++prev)} />
				<Separator />
				<InputRange
					id={'ArraySorting-speed-selection'}
					text={'Speed'}
					min={0}
					max={10}
					value={speed}
					onChange={(e) => setSpeed(e.target.value as unknown as number)}
				/>
				<InputRange
					id={'ArraySorting-number-of-element-selection'}
					text={'Number of elements'}
					min={4}
					max={200}
					value={numberOfElement}
					onChange={(e) => setNumberOfElement(e.target.value as unknown as number)}
				/>
				<Separator />
				<InputRadio
					id={'ArraySorting-merge-sort'}
					text={'Merge sort'}
					value={SortAlgo.Merge}
					checked={sortAlgo === SortAlgo.Merge}
					onChange={(e) => setSortAlgo(e.target.value as unknown as SortAlgo.Merge)}
				/>
				<InputRadio
					id={'ArraySorting-quick-sort'}
					text={'Quick sort'}
					value={SortAlgo.Quick}
					checked={sortAlgo === SortAlgo.Quick}
					onChange={(e) => setSortAlgo(e.target.value as unknown as SortAlgo.Quick)}
				/>
				<InputRadio
					id={'ArraySorting-heap-sort'}
					text={'Heap sort'}
					value={SortAlgo.Heap}
					checked={sortAlgo === SortAlgo.Heap}
					onChange={(e) => setSortAlgo(e.target.value as unknown as SortAlgo.Merge)}
				/>
				<InputRadio
					id={'ArraySorting-bubble-sort'}
					text={'Bubble sort'}
					value={SortAlgo.Bubble}
					checked={sortAlgo === SortAlgo.Bubble}
					onChange={(e) => setSortAlgo(e.target.value as unknown as SortAlgo.Bubble)}
				/>
			</Sidebar>
			<div id={'ArraySorting-container-element'} className={'ArraySorting-container-element'}>
				<div className={'ArraySorting-element'}></div>
			</div>
		</div>
	);
};

type Props = Record<string, never>;

export default ArraySorting;
