import { CASE_TYPE } from '../../../../components/pages/PathfinderVisualizer/ArrayContext';

export function* basicRandom(array: number[][]): IterableIterator<{ [s: number]: number[] }> {
	for (let i = 0; i < array.length; i++) {
		for (let j = 0; j < array[i].length; j++) {
			if (Math.random() < 0.3 && array[i][j] === CASE_TYPE.EMPTY) {
				array[i][j] = CASE_TYPE.WALL;
				// yield { 1: [] };
			}
		}
		yield { 1: [] };
	}
}
