import React from 'react';
import AppRoutes from './components/atoms/AppRoutes';
import { UserProvider } from './contexts/User';

function App(): JSX.Element {
	return (
		<div className="App">
			<UserProvider>
				<AppRoutes />
			</UserProvider>
		</div>
	);
}

export default App;
