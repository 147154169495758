import * as React from 'react';
import MarkdownViewer from '../../atoms/MarkdownViewer';

const Article: React.FC<Props> = ({ file }) => {
	return <MarkdownViewer file={file} />;
};

type Props = {
	file: string;
};

export default Article;
