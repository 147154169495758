import React from 'react';
import Button from '../../atoms/Button';

export default function LoginOAuth2(props: Readonly<Props>): JSX.Element {
	const API_BASE_URL = process.env.REACT_APP_API_URL || '';
	const { isLogin, setIsLogin } = props;

	const logout = () => {
		setIsLogin(false);
		window.location.href = `${API_BASE_URL}/logout`;
	};

	const handleLogin = (provider: string) => {
		window.location.href = `${API_BASE_URL}/oauth2/authorization/${provider}`;
	};

	return (
		<div>
			{isLogin ? (
				<div>
					<Button text={'Log out'} onClick={logout} />
				</div>
			) : (
				<div>
					<div>
						<Button text={'Log in with google'} onClick={() => handleLogin('google')} />
					</div>
					<div>
						<Button text={'Log in with github'} onClick={() => handleLogin('github')} />
					</div>
					<div>
						<Button text={'Log out'} onClick={logout} />
					</div>
				</div>
			)}
		</div>
	);
}

interface Props {
	isLogin: boolean;
	setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}
