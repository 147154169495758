import * as React from 'react';
import './styles.css';

const SeparatorVertical: React.FC<Props> = () => {
	return <div className="SeparatorVertical" data-testid={'SeparatorVertical'}></div>;
};

type Props = Record<string, never>;

export default SeparatorVertical;
