import * as React from 'react';

const CircleGitlab: React.FC<Props> = () => {
	return (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			width="10%"
			height="10%"
			viewBox="0 0 372.000000 362.000000"
			preserveAspectRatio="xMidYMid meet"
			fill="#000000"
		>
			<g transform="translate(0.000000,362.000000) scale(0.100000,-0.100000)" stroke="none">
				<path
					d="M1720 3520 c-520 -64 -974 -346 -1263 -785 -337 -512 -376 -1169
        -101 -1716 220 -440 635 -782 1105 -909 318 -86 676 -77 994 26 116 37 345
    154 455 231 144 102 350 312 443 453 136 206 223 413 269 642 19 97 23 144 23
    328 0 184 -4 231 -23 328 -66 329 -222 626 -454 868 -268 278 -595 455 -963
    520 -108 18 -381 26 -485 14z m-239 -1165 l103 -315 329 2 330 3 99 305 c54
    168 103 309 107 313 5 5 19 7 32 5 27 -3 20 16 218 -595 96 -295 119 -377 112
    -396 -12 -32 -886 -681 -906 -673 -22 8 -868 637 -882 655 -7 9 -13 25 -13 35
    0 10 57 195 126 410 69 215 130 407 136 426 6 19 19 59 30 88 17 46 23 52 48
    52 l29 0 102 -315z"
				/>
				<path
					d="M1136 2018 c14 -24 736 -963 752 -978 8 -8 12 -10 10 -5 -5 9 -249
    771 -299 933 l-20 62 -225 0 c-178 0 -224 -3 -218 -12z"
				/>
				<path
					d="M2088 1537 c-86 -270 -155 -493 -153 -495 1 -2 159 201 351 450 191
    249 363 472 382 496 l34 42 -228 0 -229 0 -157 -493z"
				/>
			</g>
		</svg>
	);
};

type Props = Record<string, never>;

export default CircleGitlab;
