import * as React from 'react';
import './styles.css';

const Separator: React.FC<Props> = () => {
	return <hr className={'Separator'} data-testid={'Separator'} />;
};

type Props = Record<string, never>;

export default Separator;
