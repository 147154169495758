import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Sidebar from '../../molecule/Sidebar';
import Button from '../../atoms/Button';
import Separator from '../../atoms/Separator';
import './styles.css';
import Dropdown from '../../atoms/Dropdown';
import InputRange from '../../atoms/InputRange';
import VisualizeArray from '../../organismes/VisualizerArray';
import { simpleStairs } from '../../../utils/algorithms/mazes/simpleStaires';
import { basicRandom } from '../../../utils/algorithms/mazes/basicRandom';
import { ArrayApiContext, ArrayDataContext } from './ArrayContext';

const PathfinderVisualizer: React.FC<Props> = () => {
	const [speed, setSpeed] = useState<number>(10);
	const [forceRender, setForceRender] = useState<number>(0);
	const { array, rerender } = useContext(ArrayDataContext);
	const { setArray, setRender, fnInitArray } = useContext(ArrayApiContext);
	const [sortIterator, setSortIterator] = useState<IterableIterator<{ [s: number]: number[] }> | undefined>(
		undefined,
	);

	useEffect(() => {
		const timer = setTimeout(function () {
			if (sortIterator === undefined) return;
			const it = sortIterator.next();
			if (!it.done) {
				setArray(array);
				setRender();
			}
		}, 500 - speed * 50);

		return () => clearTimeout(timer);
	}, [rerender]);

	return (
		<div className={'PathfinderVisualizer-container'}>
			<Sidebar>
				<Dropdown text={'Algorithms'} side={'left'}>
					<p>{"Dijkstra's Algorithm"}</p>
					<Separator />
					<p>A* Search</p>
					<Separator />
					<p>Greedy Best-first Search</p>
					<Separator />
					<p>Swarm Algorithm</p>
					<Separator />
					<p>Convergent Swarm Algorithm</p>
					<Separator />
					<p>Bidirectional Swarm Algorithm</p>
					<Separator />
					<p>Breadth-first Search</p>
					<Separator />
					<p>Depth-first Search</p>
				</Dropdown>
				<Dropdown text={'Mazes & Patterns'} side={'left'}>
					<p>Recursive Division</p>
					<p>Recursive Division (vertical skew)</p>
					<p>Recursive Division (horizontal skew)</p>
					<Button
						text={'Basic Random Maze'}
						onClick={() => {
							setSortIterator(basicRandom(array));
							setRender();
						}}
					/>
					<p>Basic Weight Maze</p>
					<Button
						text={'Simple Stair Pattern'}
						onClick={() => {
							setSortIterator(simpleStairs(array));
							setRender();
						}}
					/>
				</Dropdown>
				<InputRange
					id={'ArraySorting-speed-selection'}
					text={'Speed'}
					min={1}
					max={10}
					value={speed}
					onChange={(e) => setSpeed(e.target.value as unknown as number)}
				/>
				<Separator />
				<Button
					text={'Reset'}
					onClick={() => {
						setArray(fnInitArray());
						setRender();
						setForceRender((prev) => ++prev);
					}}
				/>
				<Button text={'Run!'} onClick={() => {}} />
			</Sidebar>
			<table className="PathfinderVisualizer-container-table">
				<tbody id="PathfinderVisualizer-container-tbody">
					<VisualizeArray forceRender={forceRender} />
				</tbody>
			</table>
		</div>
	);
};
type Props = Record<string, never>;

export default PathfinderVisualizer;
