import * as React from 'react';
import './styles.css';

const Button: React.FC<Props> = ({ text, onClick }) => {
	return (
		<button className={'button'} type={'button'} onClick={onClick}>
			{text}
		</button>
	);
};

type Props = {
	text: string;
	onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export default Button;
