import * as React from 'react';
import HeaderContainer from '../../organismes/Header/controller';
import Main from '../../organismes/Main';
import Footer from '../../organismes/Footer';
import './styles.css';

const PagesWrapper: React.FC<Props> = ({ children }: Props) => {
	return (
		<div className={'PagesWrapper'}>
			<HeaderContainer />
			<Main>{children}</Main>
			<Footer />
		</div>
	);
};

type Props = {
	children: React.ReactNode;
};

export default PagesWrapper;
