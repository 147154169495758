import * as React from 'react';
import { useContext } from 'react';
import LoginOAuth2 from '../../organismes/LoginOAuth2';
import { UserContext } from '../../../contexts/User';

const Home: React.FC<Props> = () => {
	const { isLogin, setIsLogin } = useContext(UserContext);

	return (
		<div>
			<LoginOAuth2 isLogin={isLogin} setIsLogin={setIsLogin} />
		</div>
	);
};

type Props = Record<string, never>;

export default Home;
