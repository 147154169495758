import * as React from 'react';

const CircleMail: React.FC<Props> = () => {
	return (
		<svg
			version="1.1"
			x="0px"
			y="0px"
			viewBox="0 0 1000 1000"
			enableBackground="new 0 0 1000 1000"
			height="10%"
			width="10%"
			fill="#000000"
		>
			<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
				<path d="M4563.2,5002.6c-902.4-80.4-1781.8-421.5-2527-977.1c-241.4-180.1-762.5-701.2-942.6-942.6c-285.5-383.2-542.2-852.6-701.2-1285.6C168.2,1190,64.7,450.5,110.7-193.2c49.8-693.5,193.5-1237.7,492.4-1843.1c264.4-538.4,498.1-864,938.8-1302.8c438.7-440.6,764.4-674.4,1302.8-938.8c605.4-298.9,1149.5-442.6,1843.1-492.4c511.5-36.4,1009.6,11.5,1549.9,147.5c1151.4,293.1,2189.8,1040.3,2864.2,2059.6c157.1,235.6,408.1,733.8,505.8,1003.9c224.2,611.2,327.6,1346.9,281.6,1988.7c-49.8,695.4-193.5,1239.5-492.4,1845c-264.4,538.3-498.1,864-938.8,1302.8c-438.7,440.6-764.4,674.4-1302.8,938.8c-346.8,170.5-582.4,258.6-917.7,344.9C5666.7,5006.4,5120.7,5052.4,4563.2,5002.6z M7908.3,1877.8l-5.7-235.6L6448.4,747.5L4996.2-147.2L3545.9,737.9L2097.5,1623l-5.7,245.2l-5.8,243.3h2914h2914L7908.3,1877.8z M7908.3-919.3l-5.7-751l-793.2,998.2C6674.5-124.2,6320,333.6,6323.8,341.3c3.8,9.6,360.2,235.6,793.2,500l785.5,484.7l5.7-747.2C7910.2,167,7910.2-505.5,7908.3-919.3z M2911.7,812.6c406.2-247.1,745.3-456,754.8-465.6c11.5-9.6-1427.3-1852.6-1561.4-2000.2c-9.6-9.6-17.2,620.7-17.2,1471.4v1490.5l42.2-23C2153,1272.4,2505.5,1059.8,2911.7,812.6z M4482.7-149.2c275.9-168.6,505.8-306.5,511.5-306.5c5.7,0,252.9,147.5,546,327.6c503.9,310.4,536.4,327.6,561.3,293.1c13.4-21.1,383.2-484.7,818.1-1032.7l793.2-996.2l-1356.4-5.8c-745.3-1.9-1967.6-1.9-2716.7,0l-1360.3,5.8L3097.6-835c703.1,883.2,823.8,1025,852.6,1009.7C3969.3,165,4208.8,19.4,4482.7-149.2z" />
			</g>
		</svg>
	);
};

type Props = Record<string, never>;

export default CircleMail;
