import { englishWebsiteText } from '../../i18e/en';

const useTranslation = (ns: string): useTranslationType => {
	const t = (text: string): string => {
		const selectedWebsiteText: textToTranslate = englishWebsiteText;
		if (selectedWebsiteText[ns] && selectedWebsiteText[ns][text]) return selectedWebsiteText[ns][text];
		else return text;
	};
	return { t };
};

type useTranslationType = {
	t: (s: string) => string;
};

export type textToTranslate = { [ns: string]: { [key: string]: string } };

export default useTranslation;
