import React from 'react';
import './styles.css';

const Sidebar: React.FC<Props> = ({ children }: Props) => {
	return (
		<div id={'sidebar'} data-testid={'Sidebar'}>
			{children}
		</div>
	);
};

type Props = {
	children: React.ReactNode;
};

export default Sidebar;
