export function* quickSort(array: number[]) {
	const swap = (array: number[], i: number, j: number) => {
		const tmp = array[i];
		array[i] = array[j];
		array[j] = tmp;
	};

	function* partionner(array: number[], left: number, right: number) {
		const pivot = array[right];
		let i = left - 1;
		for (let j = left; j < right; j++) {
			if (pivot > array[j]) {
				i++;
				swap(array, i, j);
			}
			yield {
				1: [pivot],
				2: [i, j],
			};
		}
		swap(array, i + 1, right);
		return i + 1;
	}

	function* startQuickSort(array: number[], left: number, right: number): any {
		if (left >= right) return;
		const pi = yield* partionner(array, left, right);
		yield* startQuickSort(array, left, pi - 1);
		yield* startQuickSort(array, pi + 1, right);
	}

	yield* startQuickSort(array, 0, array.length - 1);
	return array;
}
