import React, { useState, createContext, useEffect, useMemo } from 'react';
import { setupInterceptors } from '../../apis/axios';

type PropsUserContext = {
	isLogin: boolean;
	setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
};
const UserContext = createContext<PropsUserContext>({} as any);

function UserProvider(props: Readonly<Props>) {
	const [isLogin, setIsLogin] = useState(false);
	useEffect(() => {
		setupInterceptors(setIsLogin);
	}, [setIsLogin]);
	const value = useMemo(() => ({ isLogin, setIsLogin }), [isLogin]);

	return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
}

export { UserContext, UserProvider };

interface Props {
	children: React.ReactNode;
}
