import React, { useEffect, useState } from 'react';
import { marked } from 'marked';

import './github-makrdown-style.css';
import './style.css';

const MarkdownViewer: React.FC<Props> = ({ file }: Props) => {
	const [markdown, setMarkdown] = useState<string>('');

	useEffect(() => {
		(async function anyNameFunction(): Promise<void> {
			const response = await fetch(file);
			const text = await response.text();
			setMarkdown(marked(text));
		})();
	}, [file]);

	return (
		<div>
			<span className={'markdown-body'} dangerouslySetInnerHTML={{ __html: markdown }} />
		</div>
	);
};

type Props = {
	file: string;
};

export default MarkdownViewer;
