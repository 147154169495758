import * as React from 'react';
import ListChallenges from '../../organismesForChallenges/ListChallenges';

const Home: React.FC<Props> = () => {
	return (
		<div>
			<ListChallenges />
		</div>
	);
};

type Props = Record<string, never>;

export default Home;
