import * as React from 'react';
import { useState } from 'react';
import Sidebar from '../../molecule/Sidebar';
import Dropdown from '../../atoms/Dropdown';
import Separator from '../../atoms/Separator';
import InputRange from '../../atoms/InputRange';
import Button from '../../atoms/Button';
import InputRadio from '../../atoms/InputRadio';
import './styles.css';
import ButtonCircle from '../../atoms/ButtonCircle';

enum TreeType {
	BinaryTree = 'binaryTree',
	BinarySearchTree = 'BinarySearchTree',
	NArrayTree = 'n-arrayTree',
	Trie = 'trie',
}

const TreeVisualizer: React.FC<Props> = () => {
	const [treeType, setTreeType] = useState<TreeType>(TreeType.BinaryTree);
	const [speed, setSpeed] = useState<number>(100);
	const generateTree = () => {};
	return (
		<div className={'PathfinderVisualizer-container'}>
			<Sidebar>
				<Dropdown text={'Algorithms'} side={'left'}>
					<p>{"Dijkstra's Algorithm"}</p>
					<p>Breadth-first Search</p>
					<Separator />
					<p>Depth-first Search</p>
				</Dropdown>
				<InputRadio
					id={'TreeVisualizer-type=tree-selection'}
					text={'Binary tree'}
					value={TreeType.BinaryTree}
					checked={treeType === TreeType.BinaryTree}
					onChange={(e) => setTreeType(e.target.value as unknown as TreeType.BinaryTree)}
				/>
				<InputRange
					id={'TreeVisualizer-speed-selection'}
					text={'Speed'}
					min={1}
					max={100}
					value={speed}
					onChange={(e) => setSpeed(e.target.value as unknown as number)}
				/>
				<Separator />
				<Button text={'Reset'} onClick={() => generateTree()} />
				<Button text={'Run!'} onClick={() => {}} />
			</Sidebar>

			<div className="body tree-visualizer-body tree-visualizer-scroll">
				<div className="tree-visualizer-tree">
					<ul>
						<li>
							<ButtonCircle text={'1'} onClick={() => {}} />
							<ul className="active">
								<li>
									<ButtonCircle text={'1'} onClick={() => {}} />
									<ul>
										<li>
											<ButtonCircle text={'1'} onClick={() => {}} />
										</li>
										<li>
											<ButtonCircle text={'1'} onClick={() => {}} />
											<ul>
												<li>
													<ButtonCircle text={'8'} onClick={() => {}} />
												</li>
												<li>
													<ButtonCircle text={'99'} onClick={() => {}} />
												</li>
												<li>
													<ButtonCircle text={'100'} onClick={() => {}} />
												</li>
											</ul>
										</li>
									</ul>
								</li>
								<li>
									<ButtonCircle text={'1'} onClick={() => {}} />
								</li>
								<li>
									<ButtonCircle text={'1'} onClick={() => {}} />
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

type Props = Record<string, never>;

export default TreeVisualizer;
