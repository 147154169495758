import * as React from 'react';
import { useEffect, useState } from 'react';
import { Boat, deleteBoat, getAllBoats, saveBoat } from '../../../apis/boats';
import './styles.css';

const Boats: React.FC<Props> = () => {
	const [boats, setBoats] = useState<Boat[]>([]);
	const [newBoatName, setBoatNewName] = useState<string>('');
	const [newBoatDescription, setBoatNewDescription] = useState<string | undefined>('');
	const [editingIndex, setEditingIndex] = useState<number | null>(null);

	useEffect(() => {
		getAllBoats().then((data) => {
			setBoats([...data]);
		});
	}, []);

	const handleAddBoat = () => {
		if (newBoatName.trim() !== '') {
			const newBoat = {
				name: newBoatName,
				description: newBoatDescription,
			};
			setBoats([...boats, newBoat]);
			setBoatNewName('');
			setBoatNewDescription('');
			saveBoat(newBoat);
		}
	};

	const handleEditBoat = (index: number) => {
		setEditingIndex(index);
		setBoatNewName(boats[index].name);
		setBoatNewDescription(boats[index].description);
	};

	const handleUpdateName = () => {
		if (editingIndex !== null) {
			const updatedBoatName = [...boats];
			updatedBoatName[editingIndex].name = newBoatName;
			updatedBoatName[editingIndex].description = newBoatDescription;
			setBoats([...updatedBoatName]);
			saveBoat(updatedBoatName[editingIndex]);
			setBoatNewName('');
			setBoatNewDescription('');
			setEditingIndex(null);
		}
	};

	const handleDeleteBoat = (index: number) => {
		const updatedNames = [...boats];
		if (updatedNames[index]?.id !== undefined) {
			deleteBoat(updatedNames[index].id!);
		}
		updatedNames.splice(index, 1);
		setBoats([...updatedNames]);
		setEditingIndex(null);
	};

	return (
		<div>
			<h2>Boat List</h2>
			<table>
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Description</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{boats.map((boatEntity, index) => (
						<tr key={index}>
							<td>{boatEntity.id}</td>
							<td>{boatEntity.name}</td>
							<td>{boatEntity.description}</td>
							<td>
								<button onClick={() => handleEditBoat(index)}>Edit</button>
								<button onClick={() => handleDeleteBoat(index)}>Delete</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div>
				<span>Name: </span>
				<input type="text" value={newBoatName} onChange={(e) => setBoatNewName(e.target.value)} />
				<span>Description</span>
				<input type="text" value={newBoatDescription} onChange={(e) => setBoatNewDescription(e.target.value)} />
				{editingIndex !== null ? (
					<button onClick={handleUpdateName}>Update</button>
				) : (
					<button onClick={handleAddBoat}>Add</button>
				)}
			</div>
		</div>
	);
};
type Props = Record<string, never>;

export default Boats;
