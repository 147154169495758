import React from 'react';
import './styles.css';

const Main: React.FC<Props> = ({ children }: Props) => {
	return <div id={'main'}>{children}</div>;
};

type Props = {
	children: React.ReactNode;
};

export default Main;
