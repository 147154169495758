export function* bubbleSort(array: number[]) {
	for (let index = 0; index < array.length - 1; index++) {
		for (let i = 0; i < array.length - 1; i++) {
			if (array[i] > array[i + 1]) {
				const tmp = array[i + 1];
				array[i + 1] = array[i];
				array[i] = tmp;
			}
			yield {
				1: [index],
				2: [i, i + 1],
			};
		}
	}
	return array;
}
