import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import Cell from '../Cell';
import { DRAGGING_ELEMENT } from '../index';

const Row: React.FC<RowPropw> = ({ row, y, dragging, setDragging, array, forceRender }) => {
	const cells = row.map((val, x) => {
		return (
			<Cell
				key={`${y}-${x}-${array[y][x]}`}
				x={x}
				y={y}
				cellType={array[y][x]}
				dragging={dragging}
				setDragging={setDragging}
				array={array}
				forceRender={forceRender}
			/>
		);
	});
	return <>{cells}</>;
};

export type RowPropw = {
	row: number[];
	y: number;
	setDragging: Dispatch<SetStateAction<DRAGGING_ELEMENT | undefined>>;
	dragging?: DRAGGING_ELEMENT;
	array: number[][];
	rowHash: string;
	forceRender: number;
};

const areEqual = (prevProps: RowPropw, newProps: RowPropw) => {
	const isCaseSametype = prevProps.rowHash === newProps.rowHash;
	const isDragging = prevProps.dragging?.x === newProps.dragging?.x;
	const isForceRender = prevProps.forceRender === newProps.forceRender;
	return isCaseSametype && isDragging && isForceRender;
};

export default React.memo(Row, areEqual);
