import * as React from 'react';

const CircleGithub: React.FC<Props> = () => {
	return (
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			height="10%"
			width="10%"
			viewBox="0 0 512 512"
			fill="#000000"
		>
			<title>github-glyph</title>
			<g id="Github">
				<circle id="back" cx="256" cy="256" r="256" />
				<g id="Github-2" data-name="Github">
					<path
						d="M256,116.18a143.38,143.38,0,0,0-45.32,279.39c7.16,1.32,9.8-3.11,9.8-6.9,0-3.42-.13-14.71-.2-26.69-39.88,8.67-48.3-16.91-48.3-16.91-6.52-16.57-15.92-21-15.92-21-13-8.9,1-8.71,1-8.71,14.39,1,22,14.78,22,14.78,12.79,21.91,33.54,15.58,41.72,11.91,1.29-9.27,5-15.59,9.1-19.17C198,319.28,164.52,307,164.52,252.05a55.48,55.48,0,0,1,14.77-38.48c-1.49-3.61-6.4-18.19,1.39-37.94,0,0,12-3.85,39.43,14.7a136,136,0,0,1,71.8,0c27.36-18.55,39.39-14.7,39.39-14.7,7.8,19.75,2.89,34.33,1.41,37.94a55.37,55.37,0,0,1,14.75,38.48c0,55.07-33.54,67.18-65.46,70.74,5.14,4.45,9.72,13.17,9.72,26.55,0,19.18-.16,34.62-.16,39.34,0,3.82,2.58,8.29,9.85,6.88A143.38,143.38,0,0,0,256,116.18Z"
						fill="#fff"
						fillRule="evenodd"
					/>
					<path
						d="M166.94,322c-.32.71-1.44.93-2.46.44s-1.62-1.44-1.29-2.16,1.43-.94,2.47-.45,1.64,1.45,1.28,2.16Zm-1.77-1.31"
						fill="#fff"
					/>
					<path
						d="M172.75,328.49c-.68.63-2,.34-2.93-.66s-1.11-2.34-.42-3,2-.34,2.94.66,1.12,2.34.41,3ZM171.38,327"
						fill="#fff"
					/>
					<path
						d="M178.4,336.75c-.88.61-2.32,0-3.2-1.24s-.88-2.8,0-3.41,2.3-.06,3.21,1.2.87,2.82,0,3.45Zm0,0"
						fill="#fff"
					/>
					<path
						d="M186.14,344.72c-.79.87-2.46.63-3.68-.55s-1.6-2.8-.82-3.66,2.48-.62,3.71.55,1.62,2.8.79,3.66Zm0,0"
						fill="#fff"
					/>
					<path
						d="M196.82,349.36c-.35,1.12-2,1.63-3.58,1.15s-2.68-1.8-2.36-2.94,2-1.66,3.59-1.15,2.68,1.79,2.34,2.94Zm0,0"
						fill="#fff"
					/>
					<path
						d="M208.56,350.21c0,1.18-1.34,2.16-3,2.19s-3.1-.92-3.12-2.09,1.34-2.16,3.06-2.19,3.1.92,3.1,2.09Zm0,0"
						fill="#fff"
					/>
					<path
						d="M219.48,348.36c.2,1.15-1,2.34-2.67,2.65s-3.21-.41-3.42-1.55,1-2.37,2.66-2.68,3.21.4,3.43,1.57Zm0,0"
						fill="#fff"
					/>
				</g>
			</g>
		</svg>
	);
};

type Props = Record<string, never>;

export default CircleGithub;
