import * as React from 'react';
import Boats from '../../organismesForChallenges/Boats';

const OWSBoatsChallenge: React.FC<Props> = () => {
	return (
		<div>
			<Boats />
		</div>
	);
};

type Props = Record<string, never>;

export default OWSBoatsChallenge;
