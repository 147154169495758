import * as React from 'react';

const Triangle: React.FC<Props> = () => {
	return (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			width="10.000000pt"
			height="10.000000pt"
			viewBox="0 0 50.000000 50.000000"
			fill="#808080"
		>
			<g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" stroke="none">
				<path
					d="M135 230 c60 -60 109 -108 109 -107 1 2 48 51 106 110 l105 107 -215
	0 -215 0 110 -110z"
				/>
			</g>
		</svg>
	);
};

type Props = Record<string, never>;

export default Triangle;
