import { AxiosResponse } from 'axios';
import kakashielAxiosInstance from '../axios';

export interface Boat {
	id?: number;
	name: string;
	description?: string;
}
const BASE_URL = '/api/v1/boats';

export const getAllBoats = async (): Promise<Boat[]> => {
	const response: AxiosResponse<Boat[]> = await kakashielAxiosInstance.get(`${BASE_URL}`);
	return response.data;
};

export const getBoatById = async (id: number): Promise<Boat> => {
	const response: AxiosResponse<Boat> = await kakashielAxiosInstance.get<Boat>(`${BASE_URL}/${id}`);
	return response.data;
};

export const saveBoat = async (newBoat: Boat): Promise<Boat> => {
	const response: AxiosResponse<Boat> = await kakashielAxiosInstance.post<Boat>(`${BASE_URL}`, newBoat);
	return response.data;
};

export const deleteBoat = async (id: number): Promise<void> => {
	await kakashielAxiosInstance.delete(`${BASE_URL}/${id}`);
};
