import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { DRAGGING_ELEMENT } from '../index';
import { CASE_TYPE } from '../../../pages/PathfinderVisualizer/ArrayContext';

const Cell: React.FC<CellProps> = ({ x, y, dragging, setDragging, cellType, array }) => {
	const [, setRenderCell] = useState<number>(0);
	let classname = '';
	if (y === 0) classname += 'top ';
	if (x === 0) classname += 'left ';
	if (array[y][x] === CASE_TYPE.WALL) classname += 'wall ';

	const onMouseLeave = (e: React.MouseEvent<HTMLDivElement>, y: number, x: number): void => {
		e.stopPropagation();
		e.preventDefault();
		if (e.buttons == 1 || e.buttons == 3) {
			let current_case = array[y][x];
			if (current_case === CASE_TYPE.WALL) {
				current_case = CASE_TYPE.EMPTY;
			} else {
				current_case = CASE_TYPE.WALL;
			}
			array[y][x] = current_case;
			setRenderCell((prev: number) => ++prev);
		}
	};

	const onDrop = (e: React.DragEvent<HTMLDivElement>, y: number, x: number): void => {
		e.stopPropagation();
		e.preventDefault();
		let current_case = array[y][x];
		if (!dragging) return;
		const { y: previousY, x: previousX, caseType } = dragging;
		if (current_case === CASE_TYPE.EMPTY) {
			current_case = caseType;
		}
		array[y][x] = current_case;
		array[previousY][previousX] = CASE_TYPE.EMPTY;
		setDragging(undefined);
		setRenderCell((prev: number) => ++prev);
	};

	const onDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
		e.stopPropagation();
		e.preventDefault();
	};

	const onDragEnter = (e: React.DragEvent<HTMLDivElement>, y: number, x: number, moving_type: CASE_TYPE): void => {
		e.stopPropagation();
		e.preventDefault();
		setDragging({ y, x, caseType: moving_type });
	};

	const onClick = (e: React.MouseEvent<HTMLDivElement>, y: number, x: number): void => {
		e.stopPropagation();
		e.preventDefault();
		if (dragging !== undefined) return;
		let current_case = array[y][x];
		if (CASE_TYPE.WALL === current_case) {
			current_case = CASE_TYPE.EMPTY;
		} else if (CASE_TYPE.EMPTY === current_case) {
			current_case = CASE_TYPE.WALL;
		} else if (
			current_case === CASE_TYPE.START ||
			current_case === CASE_TYPE.STEP ||
			current_case === CASE_TYPE.FINISH
		) {
			current_case = CASE_TYPE.EMPTY;
		}
		array[y][x] = current_case;
		setRenderCell((prev: number) => ++prev);
	};
	return (
		<td
			key={`PathfinderVisualizer-row-${y}-${x}-${cellType}`}
			id={`PathfinderVisualizer-row-${y}-${x}`}
			className={classname}
		>
			{array[y][x] === CASE_TYPE.START && (
				<div
					style={{ height: '100%', width: '100%' }}
					draggable="true"
					onDragEnter={(e) => onDragEnter(e, y, x, CASE_TYPE.START)}
					className="icon-location"
					data-testid={`cell-${y}-${x}`}
				/>
			)}
			{array[y][x] === CASE_TYPE.FINISH && (
				<div
					style={{ height: '100%', width: '100%' }}
					className="icon-home-location"
					draggable="true"
					onDragEnter={(e) => onDragEnter(e, y, x, CASE_TYPE.FINISH)}
					data-testid={`cell-${y}-${x}`}
				/>
			)}
			{array[y][x] === CASE_TYPE.STEP && (
				<div
					style={{ height: '100%', width: '100%' }}
					className="icon-drop-location"
					draggable="true"
					onDragEnter={(e) => onDragEnter(e, y, x, CASE_TYPE.STEP)}
					data-testid={`cell-${y}-${x}`}
				/>
			)}
			{array[y][x] === CASE_TYPE.WALL && (
				<div
					style={{ height: '100%', width: '100%', position: 'absolute' }}
					onMouseLeave={(e) => onMouseLeave(e, y, x)}
					onClick={(e) => onClick(e, y, x)}
					data-testid={`cell-${y}-${x}`}
				/>
			)}
			{array[y][x] === CASE_TYPE.EMPTY && (
				<div
					style={{ height: '100%', width: '100%' }}
					onDrop={(e) => onDrop(e, y, x)}
					onDragOver={onDragOver}
					onMouseLeave={(e) => onMouseLeave(e, y, x)}
					onClick={(e) => onClick(e, y, x)}
					data-testid={`cell-${y}-${x}`}
				/>
			)}
		</td>
	);
};

type CellProps = {
	y: number;
	x: number;
	setDragging: Dispatch<SetStateAction<DRAGGING_ELEMENT | undefined>>;
	dragging?: DRAGGING_ELEMENT;
	cellType: number;
	array: number[][];
	forceRender: number;
};

const areEqual = (prevProps: CellProps, newProps: CellProps) => {
	const isCaseSametype = prevProps.cellType === newProps.cellType;
	const isDragging = prevProps.dragging?.x === newProps.dragging?.x;
	const isForceRender = prevProps.forceRender === newProps.forceRender;
	return isCaseSametype && isDragging && isForceRender;
};

export default React.memo(Cell, areEqual);
