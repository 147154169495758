import * as React from 'react';
import './styles.css';
import CircleLinkedin from '../../atoms/Icons/CircleLinkedin';
import CircleGithub from '../../atoms/Icons/CircleGithub';
import CircleMail from '../../atoms/Icons/CircleMail';
import CircleGitlab from '../../atoms/Icons/CircleGitlab';
import useTranslation from '../../../hooks/useTranslation';
import Link from '../../atoms/Link';

const Footer: React.FC<Props> = () => {
	const { t } = useTranslation('footer');
	return (
		<div id={'footer'}>
			<div id={'footer-icon-row'}>
				<Link to={`mailto: ${t('circleMail')}`}>
					<CircleMail />
				</Link>
				<Link to={t('circleLinkedin')}>
					<CircleLinkedin />
				</Link>
				<Link to={t('circleGitlab')}>
					<CircleGitlab />
				</Link>
				<Link to={t('circleGithub')}>
					<CircleGithub />
				</Link>
			</div>
			<div id={'footer-text-row'}>{t('footerText')}</div>
		</div>
	);
};
type Props = Record<string, never>;

export default Footer;
