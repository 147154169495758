import React from 'react';
import './styles.css';
import yo from './yo.png';

const Profile: React.FC<Props> = () => {
	return <img id={'Profile'} src={yo} alt={'Profile'} />;
};

type Props = Record<string, never>;
export default Profile;
